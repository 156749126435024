import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import AboutApp from "components/AboutApp";
import CtaBlock from "components/CtaBlock";
import FaqBlock from "components/FaqBlock";
import Hero from "components/Hero";
import KeyDifferences from "components/KeyDifferences";
import LmpBlock from "components/LmpBlock";
import StepsBlock from "components/StepsBlock";
import { useRef } from "react";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";
import cookieBanner from "assets/images/cookiebanner.png";

export default function Home({ lmpRef }: any) {
    const keyDiff = useRef(null);

    const loadTrackingScripts = () => {
        // Facebook Pixel and Google Analytics
        const fbScript = `
         !function (f, b, e, v, n, t, s) {
          if (f.fbq) return; n = f.fbq = function () {
            n.callMethod ?
              n.callMethod.apply(n, arguments) : n.queue.push(arguments)
          };
          if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
          n.queue = []; t = b.createElement(e); t.async = !0;
          t.src = v; s = b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t, s)
        }(window, document, 'script',
          'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '1632308090735075');
        fbq('track', 'PageView');
    `;

        const scriptElement = document.createElement('script');
        scriptElement.innerHTML = fbScript;
        document.head.appendChild(scriptElement);

        const gtagScript = document.createElement('script');
        gtagScript.src = "https://www.googletagmanager.com/gtag/js?id=AW-16760452142";
        gtagScript.async = true;
        document.head.appendChild(gtagScript);

        // Adalyser Script
        const adalyserScript = document.createElement('script');
        adalyserScript.innerHTML = `
        (function (clientId) {
            if (window["__adal_disable_" + clientId]) { return; }
            (function (windowAlias, documentAlias, trackerName) {
                if (!windowAlias[trackerName]) {
                    windowAlias.GlobalAdalyserNamespace = windowAlias.GlobalAdalyserNamespace || [];
                    windowAlias.GlobalAdalyserNamespace.push(trackerName);
                    windowAlias[trackerName] = function () {
                        (windowAlias[trackerName].q = windowAlias[trackerName].q || []).push(arguments)
                    };
                    windowAlias[trackerName].q = windowAlias[trackerName].q || [];
                    var nel = documentAlias.createElement("script"),
                        fel = documentAlias.getElementsByTagName("script")[0];
                    nel.async = 1;
                    nel.src = "//c5.adalyser.com/adalyser.js?cid=" + clientId;
                    fel.parentNode.insertBefore(nel, fel)
                }
            }(window, document, "adalyserTracker"));
            window.adalyserTracker("create", {
                campaignCookieTimeout: 15552000,
                conversionCookieTimeout: 604800,
                clientId: clientId,
                trafficSourceInternalReferrers: [
                    "^(.*\\.)?crashlaw24.*$"
                ]
            });
            window.adalyserTracker("trackSession", "lce1");
        })("crashlaw24");
    `;
        document.head.appendChild(adalyserScript);

        // Google Analytics
        const gtagScript1 = document.createElement('script');
        gtagScript1.src = "https://www.googletagmanager.com/gtag/js?id=AW-16760452142";
        gtagScript1.async = true;
        document.head.appendChild(gtagScript1);

        const gtagScript2 = document.createElement('script');
        gtagScript2.src = "https://www.googletagmanager.com/gtag/js?id=G-GJNWL5V3X2";
        gtagScript2.async = true;
        document.head.appendChild(gtagScript2);

        const gtagConfig = `
            window.dataLayer = window.dataLayer || [];
            function gtag() { dataLayer.push(arguments); }
            gtag('js', new Date());
            gtag('config', 'AW-16760452142');
            gtag('config', 'G-GJNWL5V3X2');
        `;

        const gtagConfigScript = document.createElement('script');
        gtagConfigScript.innerHTML = gtagConfig;
        document.head.appendChild(gtagConfigScript);

        const googleTagManagerScript = document.createElement('script');
        googleTagManagerScript.innerHTML = `
            <!-- Google Tag Manager -->
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-M8MFR3RS');
        <!-- End Google Tag Manager -->
        `;
        googleTagManagerScript.async = true;
        document.head.appendChild(googleTagManagerScript);
    };

    return (
        <Box>
            <Hero />
            <AboutApp keyDiff={keyDiff} />
            <StepsBlock />
            <div ref={keyDiff}>
                <KeyDifferences />
            </div>
            <FaqBlock />
            <CtaBlock />
            <div ref={lmpRef}>
                <LmpBlock />
            </div>
            <CookieConsent
                location="bottom"
                buttonText="Accept all cookies"
                declineButtonText="Only essential"
                cookieName="cookie_consent"
                flipButtons={true}
                style={{
                    display: "flex",
                    alignItems: "center",
                    height: "286px",
                    width: "99%",
                    left: "10px",
                    backgroundImage: `url(${cookieBanner})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    borderRadius: "15px",
                    padding: "0 100px",
                }}
                buttonStyle={{
                    color: "white",
                    fontSize: "16px",
                    fontWeight: "700",
                    fontFamily: "Univia Pro",
                    backgroundColor: "#3399FF",
                    borderRadius: "100px",
                    padding: "10px 45px",
                }}
                declineButtonStyle={{
                    color: "white",
                    fontSize: "16px",
                    fontWeight: "700",
                    fontFamily: "Univia Pro",
                    backgroundColor: "#3399FF",
                    borderRadius: "100px",
                    padding: "10px 45px",
                }}
                expires={150}
                enableDeclineButton
                onAccept={() => {
                    loadTrackingScripts();
                }}
                onDecline={() => {
                    console.log("declined");
                }}
            >
                <Box sx={{ display: "flex", flexDirection: "column", gap: "8px", width: "340px" }}>
                    <Typography sx={{ fontSize: "30px", fontWeight: "700" }}>Cookie notice</Typography>
                    <Typography sx={{ fontWeight: "450", fontFamily: "Neue Haas Grotesk Display light", fontSize: "14px" }}>
                        This website employs cookies to improve user experience. If you continue on this website you will be providing your consent to our use of all cookies.
                    </Typography>

                    <Link to="/privacy-policy" style={{ color: "white", fontFamily: "Univia Pro", marginTop: "40px", fontWeight: "700", textDecoration: "underline", fontSize: "14px" }}>
                        Read our Privacy Policy here
                    </Link>
                </Box>
            </CookieConsent>
        </Box>
    );
};

import { ThemeProvider } from "@mui/material";
import AuthProvider from "contexts/AuthProvider";
import ProtectedRoute from "contexts/ProtectedRoute";
import FaqPage from "pages/Faqs";
import PrivacyPolicyPage from "pages/PrivacyPolicy";
import ProfilePage from "pages/Profile";
import TermsAndConditionsPage from "pages/TermsAndConditions";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import FormTheme from "themes/FormTheme";
import "./App.css";
import ScrollToTop from "./hooks/scrollToTop";
import Contact from "./pages/Contact";
import Dashboard from "./pages/Dashboard";
import ForgotPassword from "./pages/ForgotPassword";
import Home from "./pages/Home";

import Layout from "./pages/Layout/Default";
import Login from "./pages/Login";
import NoPage from "./pages/NoPage";
import Register from "./pages/Register";
import ResetPassword from "./pages/ResetPassword";
import Verification from "./pages/Verification";
import { useRef } from "react";
import RegisterSuccess from "pages/RegisterSuccess";
import SubscriptionSuccess from "pages/SubscriptionSuccess";
import ThankYou from "pages/ThankYou";

function App() {
  const lmpRef = useRef(null);

  return (
    <BrowserRouter>
      <AuthProvider>
        <ThemeProvider theme={FormTheme}>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Layout lmpRef={lmpRef} />}>
              <Route index element={<Home lmpRef={lmpRef} />} />
              <Route path="login" element={<Login />} />
              <Route path="register" element={<Register />} />
              <Route path="forgot-password" element={<ForgotPassword />} />
              <Route path="reset-password/:code" element={<ResetPassword />} />
              <Route path="faqs" element={<FaqPage />} />
              <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
              <Route path="profile" element={<ProfilePage />} />
              <Route
                path="terms-and-conditions"
                element={<TermsAndConditionsPage />}
              />
              <Route path="contact" element={<Contact />} />
              <Route
                path="verification"
                element={
                  <ProtectedRoute>
                    <Verification />
                  </ProtectedRoute>
                }
              />
              <Route
                path="dashboard"
                element={
                  <ProtectedRoute>
                    <Dashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path="register-success"
                element={
                  <RegisterSuccess />
                }
              />
              <Route
                path="subscription-success"
                element={
                  <SubscriptionSuccess />
                }
              />
              <Route path="thank-you" element={<ThankYou />} />
              <Route path="*" element={<NoPage />} />
            </Route>
          </Routes>
        </ThemeProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
